import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MuseClient } from 'muse-js';
import connect from '../muse_util/connect';
import Plot from 'react-plotly.js';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { v4 as uuidv4 } from 'uuid';

const RecordingPage = () => {
  const [eegData, setEegData] = useState({
    time: [],
    signal0: [],
    signal1: [],
    signal2: [],
    signal3: [],
  });

  const [ppgData, setPpgData] = useState({
    time: [],
    ppg1: [],
    ppg2: [],
    ppg3: [],
  });

  const [isConnected, setIsConnected] = useState(false);
  const [museClient, setMuseClient] = useState(null);
  const [uploadInterval, setUploadInterval] = useState(null);
  const [startTime, setStartTime] = useState(null);  // Store the recording start time

  const client = new S3Client({
    region: process.env.REACT_APP_REGION,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: process.env.REACT_APP_REGION },
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    }),
  });
  const bucketName = process.env.REACT_APP_BUCKET_NAME;

  const initializeConnection = async () => {
    try {
      let client = new MuseClient();
      client.enablePpg = true;
      await client.connect();
      await client.start();

      if (client) {
        setIsConnected(true);
        setMuseClient(client);

        const recordingStartTime = new Date(); // Record the start time of the recording
        setStartTime(recordingStartTime); // Set the start time in state

        client.ppgReadings.subscribe((ppgReading) => {
          let currentTime = ppgReading.timestamp;
          let ppgchannel = ppgReading.ppgChannel;
          let samples = ppgReading.samples;

          setPpgData((prevData) => ({
            ...prevData,
            time: [...prevData.time, currentTime],
            ...(ppgchannel === 0 && { ppg1: [...prevData.ppg1, samples] }),
            ...(ppgchannel === 1 && { ppg2: [...prevData.ppg2, samples] }),
            ...(ppgchannel === 2 && { ppg3: [...prevData.ppg3, samples] }),
          }));
        });

        client.eegReadings.subscribe((reading) => {
          let currentTime = reading.timestamp;
          let electrode = reading.electrode;
          let samples = reading.samples;

          setEegData((prevData) => ({
            ...prevData,
            time: [...prevData.time, currentTime],
            ...(electrode === 0 && { signal0: [...prevData.signal0, samples] }),
            ...(electrode === 1 && { signal1: [...prevData.signal1, samples] }),
            ...(electrode === 2 && { signal2: [...prevData.signal2, samples] }),
            ...(electrode === 3 && { signal3: [...prevData.signal3, samples] }),
          }));
        });

        // Start uploading every 1h (3,600,000 ms)
        const interval = setInterval(() => {
          const csvData = convertToCSV(eegData, ppgData);
          uploadToS3(csvData, recordingStartTime);
        }, 3600000); // 1h in milliseconds

        setUploadInterval(interval);
      }
    } catch (error) {
      console.error('Error connecting to Muse:', error);
    }
  };

  // Filter data to show the most recent 1 second
  const filterRecentData = (timeArray, dataArray) => {
    const currentTime = timeArray.length > 0 ? timeArray[timeArray.length - 1] : 0;
    const oneSecondAgo = currentTime - 1000;
    const recentDataIndices = timeArray.reduce((indices, time, index) => {
      if (time > oneSecondAgo) indices.push(index);
      return indices;
    }, []);

    return {
      time: recentDataIndices.map(index => timeArray[index]),
      data: recentDataIndices.map(index => dataArray[index]),
    };
  };

  const convertToCSV = (eegData, ppgData) => {
    const headers = ['Time', 'Signal0', 'Signal1', 'Signal2', 'Signal3', 'PPG1', 'PPG2', 'PPG3'];
    const maxLength = Math.max(eegData.time.length, ppgData.time.length);

    const rows = Array.from({ length: maxLength }, (_, index) => [
      eegData.time[index] || ppgData.time[index] || '',
      eegData.signal0[index] || '',
      eegData.signal1[index] || '',
      eegData.signal2[index] || '',
      eegData.signal3[index] || '',
      ppgData.ppg1[index] || '',
      ppgData.ppg2[index] || '',
      ppgData.ppg3[index] || '',
    ]);

    return [headers, ...rows].map(e => e.join(",")).join("\n");
  };

  // Function to format date for filenames
  const formatDateForFilename = (date) => {
    return date.toISOString().replace(/:/g, '-'); // Replace ':' with '-' to make it valid for file names
  };

  const uploadToS3 = async (csvContent, startTime) => {
    const saveTime = new Date(); // The current time when data is saved
    const startTimeMilliseconds = startTime.getTime();  // Get the start time in milliseconds
    const saveTimeMilliseconds = saveTime.getTime();    // Get the save time in milliseconds
    
    const dateStart = new Date(startTimeMilliseconds);
    const dateEnd = new Date(saveTimeMilliseconds);

    const fileName = `eeg_data_start_${dateStart.getMonth() + 1}_${dateStart.getDate()}_${dateStart.getFullYear()}_${dateStart.getHours()}_${dateStart.getMinutes()}_${dateStart.getSeconds()}_end_${dateEnd.getMonth() + 1}_${dateEnd.getDate()}_${dateEnd.getFullYear()}_${dateEnd.getHours()}_${dateEnd.getMinutes()}_${dateEnd.getSeconds()}.csv`;
  
    try {
      const params = {
        Bucket: bucketName,
        Key: fileName,
        Body: csvContent,
        ContentType: "text/csv",
      };
      await client.send(new PutObjectCommand(params));
      console.log(`Successfully uploaded data to ${bucketName}/${fileName}`);
    } catch (err) {
      console.error('Error uploading to S3:', err);
    }
  };

  const stopConnection = () => {
    if (museClient) {
      museClient.disconnect();
      setIsConnected(false);
      setMuseClient(null);

      const csvData = convertToCSV(eegData, ppgData);
      uploadToS3(csvData, startTime); // Pass the start time to the upload function

      setEegData({
        time: [],
        signal0: [],
        signal1: [],
        signal2: [],
        signal3: [],
      });

      setPpgData({
        time: [],
        ppg1: [],
        ppg2: [],
        ppg3: [],
      });

      console.log('Connection terminated, data saved to S3, and data reset.');

      // Clear the interval when stopping connection
      if (uploadInterval) {
        clearInterval(uploadInterval);
        setUploadInterval(null);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (museClient) {
        museClient.disconnect();
      }
      if (uploadInterval) {
        clearInterval(uploadInterval);
      }
    };
  }, [museClient, uploadInterval]);

  return (
    <div className="main-page">
      <div className="content">
        <h1>EEG Recording Page</h1>
        {!isConnected ? (
          <button onClick={initializeConnection}>Connect EEG Device</button>
        ) : (
          <button onClick={stopConnection}>Stop Connection</button>
        )}
        {isConnected && <p>Connected to Muse device and reading EEG data...</p>}
        {/* Plotting EEG data */}
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          {/* EEG Channel 0 */}
          <Plot
            data={[
              {
                x: filterRecentData(eegData.time, eegData.signal0).time,
                y: filterRecentData(eegData.time, eegData.signal0).data,
                type: 'scatter',
                mode: 'lines',
                line: { color: 'blue' },
              },
            ]}
            layout={{
              title: 'EEG Signal - Channel 0',
              xaxis: { title: 'Time' },
              yaxis: { title: 'EEG Signal' },
            }}
            style={{ width: '400px', height: '300px' }}
          />
          {/* EEG Channel 1 */}
          <Plot
            data={[
              {
                x: filterRecentData(eegData.time, eegData.signal1).time,
                y: filterRecentData(eegData.time, eegData.signal1).data,
                type: 'scatter',
                mode: 'lines',
                line: { color: 'blue' },
              },
            ]}
            layout={{
              title: 'EEG Signal - Channel 1',
              xaxis: { title: 'Time' },
              yaxis: { title: 'EEG Signal' },
            }}
            style={{ width: '400px', height: '300px' }} 
          />
          {/* EEG Channel 2 */}
          <Plot
            data={[
              {
                x: filterRecentData(eegData.time, eegData.signal2).time,
                y: filterRecentData(eegData.time, eegData.signal2).data,
                type: 'scatter',
                mode: 'lines',
                line: { color: 'blue' },
              },
            ]}
            layout={{
              title: 'EEG Signal - Channel 2',
              xaxis: { title: 'Time' },
              yaxis: { title: 'EEG Signal' },
            }}
            style={{ width: '400px', height: '300px' }} 
          />
          {/* EEG Channel 3 */}
          <Plot
            data={[
              {
                x: filterRecentData(eegData.time, eegData.signal3).time,
                y: filterRecentData(eegData.time, eegData.signal3).data,
                type: 'scatter',
                mode: 'lines',
                line: { color: 'blue' },
              },
            ]}
            layout={{
              title: 'EEG Signal - Channel 3',
              xaxis: { title: 'Time' },
              yaxis: { title: 'EEG Signal' },
            }}
            style={{ width: '400px', height: '300px' }} 
          />
        </div>

        {/* Plotting PPG data */}
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start'  }}>
          {/* PPG Channel 1 */}
          <Plot
            data={[
              {
                x: filterRecentData(ppgData.time, ppgData.ppg1).time,
                y: filterRecentData(ppgData.time, ppgData.ppg1).data,
                type: 'scatter',
                mode: 'lines',
                line: { color: 'red' },
              },
            ]}
            layout={{
              title: 'PPG Signal - Channel 1',
              xaxis: { title: 'Time' },
              yaxis: { title: 'PPG Signal' },
            }}
            style={{ width: '400px', height: '300px' }}
          />

          {/* PPG Channel 2 */}
          <Plot
            data={[
              {
                x: filterRecentData(ppgData.time, ppgData.ppg2).time,
                y: filterRecentData(ppgData.time, ppgData.ppg2).data,
                type: 'scatter',
                mode: 'lines',
                line: { color: 'green' },
              },
            ]}
            layout={{
              title: 'PPG Signal - Channel 2',
              xaxis: { title: 'Time' },
              yaxis: { title: 'PPG Signal' },
            }}
            style={{ width: '400px', height: '300px' }}
          />

          {/* PPG Channel 3 */}
          <Plot
            data={[
              {
                x: filterRecentData(ppgData.time, ppgData.ppg3).time,
                y: filterRecentData(ppgData.time, ppgData.ppg3).data,
                type: 'scatter',
                mode: 'lines',
                line: { color: 'purple' },
              },
            ]}
            layout={{
              title: 'PPG Signal - Channel 3',
              xaxis: { title: 'Time' },
              yaxis: { title: 'PPG Signal' },
            }}
            style={{ width: '400px', height: '300px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default RecordingPage;