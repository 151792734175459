import React, { useEffect, useState, useCallback } from "react";
import {
  ListObjectsCommand,
  GetObjectCommand,
  S3Client,
  CreateMultipartUploadCommand,
  UploadPartCommand,
  CompleteMultipartUploadCommand,
} from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

function FileExchangePage() {
  const [objects, setObjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDownFile, setSelectedDownFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [password, setPassword] = useState(""); // New state for password input
  const [passwordError, setPasswordError] = useState(""); // State for password error

  const client = new S3Client({
    region: process.env.REACT_APP_REGION,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: process.env.REACT_APP_REGION },
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    }),
  });

  const bucketName = process.env.REACT_APP_FILEEXG_BUCKET_NAME;
  const correctPassword = process.env.REACT_APP_DOWNLOAD_PASSWORD; // The correct password from environment variable

  const fetchObjects = useCallback(async () => {
    setLoading(true);
    const command = new ListObjectsCommand({ Bucket: bucketName });
    try {
      const { Contents } = await client.send(command);
      setObjects(Contents || []);
      setError('');
    } catch (err) {
      console.error('Error fetching data from S3:', err);
      setError('Failed to load data.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchObjects();
  }, []);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleMultipartUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file first.');
      return;
    }

    try {
      const createCommand = new CreateMultipartUploadCommand({
        Bucket: bucketName,
        Key: selectedFile.name,
      });
      const { UploadId } = await client.send(createCommand);

      const partSize = 10 * 1024 * 1024;
      const partCount = Math.ceil(selectedFile.size / partSize);

      const uploadPart = async (partNumber, body) => {
        const uploadPartCommand = new UploadPartCommand({
          Bucket: bucketName,
          Key: selectedFile.name,
          UploadId,
          PartNumber: partNumber,
          Body: body,
        });
        const result = await client.send(uploadPartCommand);
        setUploadProgress((prev) => prev + (100 / partCount));
        return { ETag: result.ETag, PartNumber: partNumber };
      };

      const concurrencyLimit = 1;
      const uploadPromises = [];
      const parts = [];

      for (let i = 0; i < partCount; i++) {
        const start = i * partSize;
        const end = Math.min(start + partSize, selectedFile.size);
        const part = selectedFile.slice(start, end);

        uploadPromises.push(uploadPart(i + 1, part).then(result => parts.push(result)));

        if (uploadPromises.length >= concurrencyLimit) {
          await Promise.all(uploadPromises);
          uploadPromises.length = 0;
        }
      }

      await Promise.all(uploadPromises);

      const completeCommand = new CompleteMultipartUploadCommand({
        Bucket: bucketName,
        Key: selectedFile.name,
        UploadId,
        MultipartUpload: { Parts: parts },
      });
      await client.send(completeCommand);

      alert("File uploaded successfully!");
      fetchObjects();
      setShowModal(false);
      setSelectedFile(null);
      setUploadProgress(0);
    } catch (err) {
      console.error("Error uploading file:", err);
      alert("Failed to upload file.");
    }
  };

  const handleFileClick = (fileKey) => {
    setSelectedDownFile(fileKey);
    setShowModal(true);
  };

  const handleDownloadFile = async () => {
    if (password !== correctPassword) {
      setPasswordError("Incorrect password.");
      return;
    }
    setPasswordError("");
    setDownloadProgress(0);
    const command = new GetObjectCommand({
      Bucket: bucketName,
      Key: selectedDownFile,
    });

    try {
      const { Body, ContentLength } = await client.send(command);
      const reader = Body.getReader();
      let downloadedBytes = 0;

      const stream = new ReadableStream({
        async start(controller) {
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            controller.enqueue(value);
            downloadedBytes += value.length;
            setDownloadProgress((downloadedBytes / ContentLength) * 100);
          }
          controller.close();
          reader.releaseLock();
        },
      });

      new Response(stream).blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = selectedDownFile;
        link.click();
        window.URL.revokeObjectURL(url);
        setShowModal(false);
        setSelectedDownFile(null);
      });
    } catch (err) {
      console.error('Error downloading file:', err);
      alert('Failed to download file.');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFile(null);
    setPassword(""); // Clear password field when closing modal
    setPasswordError("");
    setUploadProgress(0);
    setDownloadProgress(0);
  };

  return (
    <div>
      <h1>File Storage and Exchange</h1>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <ul>
          {objects.map((object, index) => (
            <li key={index}>
              <a href="#" onClick={() => handleFileClick(object.Key)}>
                {object.Key}
              </a>
            </li>
          ))}
        </ul>
      )}
      <button onClick={() => setShowModal(true)}>Upload File</button>
      {showModal && (
        <div style={{ position: 'fixed', top: '20%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', zIndex: 1000, border: '1px solid black', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
          <button onClick={handleCloseModal} style={{ position: 'absolute', top: '10px', right: '10px', border: 'none', background: 'none', fontSize: '20px', cursor: 'pointer' }}>×</button>
          <h2>{selectedDownFile ? "Download File" : "Upload File"}</h2>
          {selectedDownFile ? (
            <>
              <input
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ marginBottom: '10px', width: '100%' }}
              />
              {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
              <button onClick={handleDownloadFile}>Start Download</button>
              <div style={{ marginTop: '10px', width: '100%', backgroundColor: '#f3f3f3', borderRadius: '5px' }}>
                <div style={{ width: `${downloadProgress}%`, height: '10px', backgroundColor: '#4caf50', borderRadius: '5px' }}></div>
              </div>
              <p>{Math.round(downloadProgress)}% downloaded</p>
            </>
          ) : (
            <>
              <input type="file" onChange={handleFileChange} />
              <button onClick={handleMultipartUpload}>Upload</button>
              <div style={{ marginTop: '10px', width: '100%', backgroundColor: '#f3f3f3', borderRadius: '5px' }}>
                <div style={{ width: `${uploadProgress}%`, height: '10px', backgroundColor: '#4caf50', borderRadius: '5px' }}></div>
              </div>
              <p>{Math.round(uploadProgress)}% uploaded</p>
            </>
          )}
        </div>
      )}
      {showModal && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }} onClick={handleCloseModal}></div>
      )}
    </div>
  );
}

export default FileExchangePage;