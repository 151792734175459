// this page is only for displaying recorded EEG data and download the data, not intended for upload data
// for upload, goes to file exchange page
import React, { useEffect, useState, useCallback } from "react";
import { ListObjectsCommand, PutObjectCommand, GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

function DataPage() {
  // 1. State definitions
  const [objects, setObjects] = useState([]); // Stores the list of objects in the S3 bucket
  const [loading, setLoading] = useState(true); // Tracks loading state
  const [error, setError] = useState(''); // Stores any error messages
  const [selectedDownFile, setSelectedDownFile] = useState(null); // Holds the currently selected file for upload/download

  // 2. S3 Client initialization
  const client = new S3Client({
    region: process.env.REACT_APP_REGION, // AWS region from environment variables
    credentials: fromCognitoIdentityPool({ // Cognito Identity Pool for AWS credentials
      clientConfig: { region: process.env.REACT_APP_REGION },
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    }),
  });

  const bucketName = process.env.REACT_APP_BUCKET_NAME; // S3 bucket name from environment variables

  // 3. Fetch S3 objects
  const fetchObjects = useCallback(async () => {
    setLoading(true); // Begin loading
    const command = new ListObjectsCommand({ Bucket: bucketName }); // Command to list objects in the bucket
    try {
      const { Contents } = await client.send(command); // Send command to S3 client
      setObjects(Contents || []); // Update state with the fetched objects
      setError(''); // Clear any errors
    } catch (err) {
      console.error('Error fetching data from S3:', err); // Log errors to console
      setError('Failed to load data.'); // Set error state
    } finally {
      setLoading(false); // End loading
    }
  }, [client]);

  // 4. Effect hook to fetch objects on mount
  useEffect(() => {
    fetchObjects(); // Call fetchObjects when the component mounts
  }, []);

  // 5. Open modal to handle file select for download
  const handleFileClick = (fileKey) => {
    setSelectedDownFile(fileKey); // Set the clicked file as selected
  };

  useEffect(() => {
    if (selectedDownFile) {
      console.log(selectedDownFile)
      handleDownloadFile(); // directly download file when clicking the link
    }
  }, [selectedDownFile]);

  // 6. Handle file download from S3
  const handleDownloadFile = async () => {
    const command = new GetObjectCommand({
      Bucket: bucketName,
      Key: selectedDownFile, // Key of the file to download
    });

    try {
      const { Body } = await client.send(command); // Request the file from S3
      const reader = Body.getReader();
      const stream = new ReadableStream({
        async start(controller) {
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            controller.enqueue(value); // Enqueue each chunk of the file
          }
          controller.close();
          reader.releaseLock();
        }
      });

      new Response(stream).blob().then(blob => {
        const url = window.URL.createObjectURL(blob); // Create a URL for the downloaded blob
        const link = document.createElement('a');
        link.href = url;
        link.download = selectedDownFile; // Set the download attribute to the file key
        link.click(); // Programmatically click the link to start download
        window.URL.revokeObjectURL(url); // Clean up the object URL
      });
      setSelectedDownFile(null);
    } catch (err) {
      console.error('Error downloading file:', err);
      alert('Failed to download file.'); // Alert if the download fails
    }
  };

  return (
    <div>
      <h1>openmind's eeg data</h1>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <ul>
          {objects.map((object, index) => (
            <li key={index}>
              <a href="#" onClick={() => handleFileClick(object.Key)}>
                {object.Key}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default DataPage;